import React from 'react';
import { Cookie } from '@silkpwa/module/cookie/cookie';
import { OverrideProductListingPage } from 'ui/component/override-product-listing-page';
import { CertonaRecommendations } from 'ui/component/product/product-widget/certona-recommendations';
import { Container } from 'ui/component/container';
import { MainColumn } from 'ui/component/main-column';
import { DocumentTitle } from '@silkpwa/module/react-component/document-title';
import { SEOTags } from 'ui/component/seo-tags';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { connectOverrideCategory } from '@silkpwa/module/react-component/connect-override-category';
import { createConnectOverridePagination } from '@silkpwa/module/react-component/create-connect-pagination';
import { Category as EcommerceCategory } from '@silkpwa/module/ecommerce-catalog/override-category/override-category';
import styles from './style.css';

const OverrideProductListing = createConnectOverridePagination((modules: {[key: string]: any}) => {
    const ecommerceCategory = modules.overrideCategory as EcommerceCategory;
    return ecommerceCategory.ducks.pagination;
})(OverrideProductListingPage);

const CategoryPageImpl = ({ category }) => {
    const t = usePhraseTranslater();
    /**
     * We have to be sure that when a customer lands on a PLP:
     * 1. the `current category id` should be memorized in cookies in order to be used on the BE for retrieving
     * the correct category and return correct breadcrumbs array, see:
     *  - API: app_product_info;
     *  - Observer: Silk\Restapp\Observer\InjectBreadcrumbsObserver.
     * 2. Breadcrumbs logic is in the `pwa-theme/packages/chefworks-theme/src/ui/component/breadcrumbs/breadcrumbs.tsx`
     */
    const cookie = new Cookie();
    cookie.set('override-category-id', category.id, 7);
    return (
        <div>
            <DocumentTitle>{category.meta_title || category.name}</DocumentTitle>
            <SEOTags info={category} />
            <div className={styles.categoryPage}>
                <MainColumn className="maincolumn-categorypage">
                    <Container id="content.top" />
                    <OverrideProductListing
                        key={category.id}
                        shouldDisplaySort
                        shouldShowRecommendations={false}
                        emptyContent={(
                            <div data-test="no-product-box">
                                <h1 className={styles.h1Style}>{t('No Products')}</h1>
                                <CertonaRecommendations />
                            </div>
                        )}
                    />
                </MainColumn>
            </div>
        </div>
    );
};

const OverrideCategoryPage = connectOverrideCategory(CategoryPageImpl);

export { OverrideCategoryPage as default };
